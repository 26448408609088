import styles from './NotificationToast.module.scss';
import React from 'react';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import { useDialogState, Dialog } from 'reakit/Dialog';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';

import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Button from '@moonshineragency/ui/src/components/Button/Button';

import classNames from 'classnames';

import PropTypes from 'prop-types';

const NotificationToast = ({
  dialog,
  title,
  children,
  theme,
  variant,
  handleClick,
  buttonText,
}) => {
  return (
    <Dialog {...dialog} aria-label="Notification">
      <div className={styles.container}>
        {theme === 'checkmark' && (
          <IconWrapper
            Icon={IconComponents.checkBig}
            alt="Notification Icon"
            className={styles.iconWrapper}
          />
        )}
        {theme === 'warning' && (
          <IconWrapper
            Icon={IconComponents.warningCircle}
            alt="Notification Icon"
            className={styles.iconWrapper}
          />
        )}
        <div
          className={classNames(styles.linkContentWrapper, {
            [styles.contentWrapper]: variant === 'button' || variant === 'text',
          })}
        >
          <Heading className={styles.heading} size="h6">
            {title}
          </Heading>
          <Content className={styles.content} size="copy75" theme="primary">
            {children}
          </Content>
        </div>
        {buttonText && (
          <div
            className={classNames(styles.buttonContainer, {
              [styles.linkContainer]: variant === 'link',
              [styles.noButton]: variant === 'text',
            })}
          >
            <Button
              className={classNames(styles.button, {
                [styles.link]: variant === 'link',
              })}
              onClick={handleClick}
              theme={variant === 'button' ? 'secondary' : 'link'}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

NotificationToast.propTypes = {
  dialog: PropTypes.objectOf(useDialogState).isRequired,
  title: PropTypes.string,
  children: PropTypes.string,
  variant: PropTypes.oneOf(['button', 'link', 'text']),
  theme: PropTypes.oneOf(['checkmark', 'warning']),
  handleClick: PropTypes.func,
};

NotificationToast.defaultProps = {
  title: '',
  children: '',
  variant: 'button',
  theme: 'checkmark',
  handleClick: null,
};

export default NotificationToast;
