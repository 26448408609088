import styles from './EditUser.module.scss';
import Layout from 'components/Layout/Layout';
import Container from 'components/Container/Container';
import DropDown from 'components/DropDown/DropDown';
import Selector from 'components/Selector/Selector';
import DataView from 'components/DataView/DataView';
import Client from 'api/client';
import BackNavigation from 'components/Navigation/BackNavigation';
import DeleteUserOverlay from 'components/DeleteUserOverlay/DeleteUserOverlay';
import { AuthorizationContextProvider } from 'contextProvider';
import PrivateElement from 'pages/admin/components/PrivateElement.js';
import NotificationToast from 'components/NotificationToast/NotificationToast';
import React, { useEffect, useState } from 'react';
import {
  unstable_FormLabel as FormLabel,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_useFormState as useFormState,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import { navigate } from 'gatsby';
import { usePopoverState } from 'reakit/Popover';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';
import Modal from '@moonshineragency/ui/src/components/Modal/Modal';

const EditUser = ({
  layoutStructure = {
    showBreadCrumbs: false,
    showTitle: false,
  },
}) => {
  const popover = usePopoverState();
  const userRoles = typeof window !== 'undefined' && window.history.state;
  const [isFormChanged, setIsFormChanged] = useState(false);
  const dialog = useDialogState();
  const emailSentDialog = useDialogState();
  const emailSentFailedDialog = useDialogState();

  const form = useFormState({
    values: {
      ...userRoles,
      first_name: '',
      last_name: '',
      email: '',
      role: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.first_name) {
        errors = {
          ...errors,
          first_name: renderInputErrorFor('Please enter first name'),
        };
      }
      if (!values.last_name) {
        errors = {
          ...errors,
          last_name: renderInputErrorFor('Please enter last name'),
        };
      }
      if (!values.email) {
        errors = {
          ...errors,
          email: renderInputErrorFor('Please enter email'),
        };
      }
      if (!values.role) {
        errors = {
          ...errors,
          role: renderInputErrorFor('Please enter role'),
        };
      }

      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async (values, errors) => {
      const requestValues = {
        ...values,
        role: values.role.toLowerCase(),
      };
      try {
        await Client.updateUser.patch(requestValues.userData.id, requestValues);
        navigate(`/admin/users/list`);
      } catch (e) {
        throw errors;
      }
    },
  });
  const deleteUserForm = useFormState({
    values: {
      delete: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.delete || values.delete !== 'DELETE') {
        errors = {
          ...errors,
          delete: renderInputErrorFor('Please enter DELETE'),
        };
      }

      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async errors => {
      try {
        await Client.deleteUser.delete(userRoles.userData.id);
        navigate(`/admin/users/list`);
      } catch (e) {
        throw errors;
      }
    },
  });

  const handleSendEmail = async () => {
    const resp = await Client.forgotPassword
      .post({ email: form.values.email })
      .catch(() => {
        emailSentFailedDialog.show();
      });

    if (resp?.status === 200) {
      emailSentDialog.show();
    }
  };

  const renderUpperCase = item =>
    item !== null && item?.charAt(0).toUpperCase() + item?.slice(1);

  const rolesOptions = form?.values?.userRoles?.map(userRole => userRole.name);
  const options = rolesOptions?.map(roleOption => {
    return {
      value: renderUpperCase(roleOption),
      label: renderUpperCase(roleOption),
    };
  });
  useEffect(() => {
    if (form.values.role) {
      popover.hide();
    }

    if (
      form.touched.role &&
      form?.values?.role !== form?.values?.userData?.role
    ) {
      setIsFormChanged(true);
    }
  }, [form.values]);

  useEffect(() => {
    form.update('first_name', form?.values?.userData?.first_name);
    form.update('last_name', form?.values?.userData?.last_name);
    form.update('email', form?.values?.userData?.email);
    form.update('role', form?.values?.userData?.role?.join());
  }, []);
  const handleClick = () => dialog.hide();
  return (
    <Layout isAdmin layoutStructure={layoutStructure}>
      <AuthorizationContextProvider>
        <PrivateElement scope="manage users">
          <Container size="md">
            <br />
            <BackNavigation to="/admin/users/list" label="All Users" />
            <NotificationToast
              dialog={emailSentDialog}
              title="Email sent"
              theme="checkmark"
              variant="button"
            >
              We&apos;ve sent a password reset link to {form.values.email}.
            </NotificationToast>
            <NotificationToast
              dialog={emailSentFailedDialog}
              title="Could not send email"
              theme="checkmark"
              variant="button"
            >
              You can&apos;t send the email at this state.
            </NotificationToast>
            <br />
            <Form form={form}>
              <Heading size="h2" theme="primary">
                {form?.values?.userData?.first_name}{' '}
                {form?.values?.userData?.last_name}
              </Heading>
              <div className={styles.inputWrapper}>
                <div className={styles.inputField}>
                  <InputField
                    type="first_name"
                    name="first_name"
                    label="First name"
                    className={styles.inputField}
                    {...form}
                    noMessage
                    onChange={() => setIsFormChanged(true)}
                  />
                  <FormMessage {...form} name="first_name" />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    type="last_name"
                    name="last_name"
                    label="Last name"
                    className={styles.inputField}
                    {...form}
                    noMessage
                    onChange={() => setIsFormChanged(true)}
                  />
                  <FormMessage {...form} name="last_name" />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    type="email"
                    name="email"
                    label="Email address"
                    className={styles.inputField}
                    {...form}
                    noMessage
                    onChange={() => setIsFormChanged(true)}
                  />
                  <FormMessage {...form} name="email" />
                </div>
                <div className={styles.inputField}>
                  <div className={styles.selectRoleLabel}>
                    <FormLabel {...form} name="role">
                      Role
                    </FormLabel>
                  </div>
                  <Selector
                    popover={popover}
                    label={
                      form?.values?.role || form?.values?.userData?.role.join()
                    }
                  >
                    <DataView
                      popover={popover}
                      name="role"
                      className={styles.userSelectorWrapper}
                    >
                      <DropDown name="role" items={options} form={form} />
                    </DataView>
                  </Selector>
                  <FormMessage {...form} name="role" />
                </div>
              </div>
            </Form>

            <div className={styles.actionWrapper}>
              <div className={styles.cancel}>
                <Form form={form}>
                  <Button
                    theme="link"
                    className={styles.link}
                    onClick={handleSendEmail}
                  >
                    Send password reset link
                  </Button>
                </Form>
                <DialogDisclosure
                  as={Button}
                  {...dialog}
                  size="75"
                  type="text"
                  className={styles.link}
                  theme="link"
                >
                  Delete user
                </DialogDisclosure>
                <Modal
                  theme="default"
                  header={
                    <Heading theme="primary" size="h2" noSpacing>
                      Delete user
                    </Heading>
                  }
                  dialog={dialog}
                >
                  <DeleteUserOverlay
                    form={deleteUserForm}
                    userRoles={userRoles}
                    onClick={handleClick}
                  />
                </Modal>
              </div>
              <Form form={form}>
                {isFormChanged && (
                  <div className={styles.cancel}>
                    <Link
                      size="75"
                      type="text"
                      className={styles.link}
                      to="/admin/users/list"
                      noUnderline
                    >
                      Cancel
                    </Link>
                    <FormSubmitButton
                      as={Button}
                      theme="primary"
                      {...form}
                      className={styles.saveButton}
                    >
                      Save
                    </FormSubmitButton>
                  </div>
                )}
              </Form>
            </div>
          </Container>
        </PrivateElement>
      </AuthorizationContextProvider>
    </Layout>
  );
};

export default EditUser;
