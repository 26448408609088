import styles from 'components/DeleteUserOverlay/DeleteUserOverlay.module.scss';
import React from 'react';
import {
  unstable_FormMessage as FormMessage,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Form from '@moonshineragency/ui/src/components/Form/Form';

const DeleteUserOverlay = ({ form, onClick, userRoles }) => {
  const submitArea = (
    <div className={styles.actionWrapper}>
      <Button onClick={onClick} theme="link" className={styles.cancelLink}>
        I changed my mind
      </Button>
      <FormSubmitButton
        className={styles.deleteButton}
        as={Button}
        theme="primary"
        size="default"
        {...form}
        isFullWidth
      >
        Delete user
      </FormSubmitButton>
    </div>
  );

  return (
    <>
      <Form submitArea={submitArea} form={form}>
        <Content size="copy75" noSpacing theme="regular">
          Are you sure you want to delete the user{' '}
          {userRoles?.userData?.first_name} {userRoles?.userData?.last_name}?
        </Content>
        <InputField
          name="delete"
          label="Type DELETE to confirm"
          {...form}
          noMessage
          className={styles.inputField}
        />
        <FormMessage {...form} name="delete" className={styles.errorMessage} />
      </Form>
    </>
  );
};

export default DeleteUserOverlay;
